.flag-icon {
  margin-right: 5px;
}
.fill { 
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.sidebar{
  background: #ccc;
}
#page-content{
  padding-left:170px; 
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow-x: hidden
}
#page-content > div{
  -webkit-transition: all 0.5s; /* Safari */
    transition: all 0.5s;
}
#page-content.collapsed{
  padding-left:50px; 
}

.content{
  padding: 20px;
}
.content.have-submenu{
  margin: 0;
}
.nav-tabs{
  padding: 10px 0 0 40px;
}
.nav-tabs > li > a {
    background: #ccc;
    font-weight: bold;
}
.form-group{
  margin-bottom: 5px;
}
label{
  margin-bottom: 0;
}
.header-button-group{
  margin-bottom: 10px;
}
.notification-container{
  top: 50px;
}
@media(min-width: 768){
  #page-content{
    margin-left:50px; 
  }
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
@media(max-width:768px) {
    .wrapper {
        padding-left: 0;
    }

    .wrapper.toggled {
        padding-left: 250px;
    }

    #sidebar-wrapper {
        width: 50px;
    }

    .page-content-wrapper {
        padding: 20px;
        position: relative;
    }
}
.bounceOutLeft-appear {
  opacity: 0;
}
.bounceOutLeft-appear.bounceOutLeft-appear-active {
  opacity: 1;
  animation-name: bounceInLeft;
  animation-duration: 400ms;
}
.bounceOutLeft-leave{
  opacity: 1
}
.bounceOutLeft-leave.bounceOutLeft-leave-active {
  opacity: 0;
  animation-name: bounceOutLeft;
  animation-duration: 400ms;
}
.zoomIn-appear {
  opacity: 0;
}
.zoomIn-appear.zoomIn-appear-active {
  opacity: 1;
  animation-name: bounceInLeft;
  animation-duration: 400ms;
}
.zoomIn-leave{
  opacity: 1
}
.zoomIn-leave.zoomIn-leave-active {
  opacity: 0;
  animation-name: zoomIn;
  animation-duration: 400ms;
}
.suggestion-content-driver{
    border-top: 1px solid #989898;
    border-left: 15px solid #989898;
    border-radius: 3px; 
		padding: 3px;
		position: relative;
}
.suggestion-content-driver.bookIn{
      border-color: #67be68;
}
.suggestion-content-driver.offered{
      border-color: #512d8c;
}
.suggestion-content-driver.booked{
      border-color: #ffc70c;
}
.suggestion-content-driver.arrived{
      border-color: #f99c1c;
}
.suggestion-content-driver.engaged{
      border-color: #a81e22;
}
.suggestion-content-driver.droppedOff{
      border-color: #ef3937;
}
.suggestion-content-driver.Waiting{
      border-color: #c34d9c;
}
.suggestion-content-driver.bookOff{
      border-color: #989898;
}
td.inactivated,
td.inactivated a{
  color: red;
}
td.activated,
td.activated a{
  color: green;
}
.no-permission-form{
  width: 100%;
  padding: 70px 10px 0 10px;
  text-align: center;
  font-size: 38px;
  font-weight: bold;
  color: #a81e22;
}


/* loading */
.cssload-thecube {
	width: 206px;
	height: 206px;
	margin: 0 auto;
	margin-top: 138px;
	position: relative;
	transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	position: relative;
	transform: rotateZ(45deg);
		-o-transform: rotateZ(45deg);
		-ms-transform: rotateZ(45deg);
		-webkit-transform: rotateZ(45deg);
		-moz-transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	transform: scale(1.1);
		-o-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
}
.cssload-thecube .cssload-cube:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(43,160,199);
	animation: cssload-fold-thecube 2.76s infinite linear both;
		-o-animation: cssload-fold-thecube 2.76s infinite linear both;
		-ms-animation: cssload-fold-thecube 2.76s infinite linear both;
		-webkit-animation: cssload-fold-thecube 2.76s infinite linear both;
		-moz-animation: cssload-fold-thecube 2.76s infinite linear both;
	transform-origin: 100% 100%;
		-o-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		-webkit-transform-origin: 100% 100%;
		-moz-transform-origin: 100% 100%;
}
.cssload-thecube .cssload-c2 {
	transform: scale(1.1) rotateZ(90deg);
		-o-transform: scale(1.1) rotateZ(90deg);
		-ms-transform: scale(1.1) rotateZ(90deg);
		-webkit-transform: scale(1.1) rotateZ(90deg);
		-moz-transform: scale(1.1) rotateZ(90deg);
}
.cssload-thecube .cssload-c3 {
	transform: scale(1.1) rotateZ(180deg);
		-o-transform: scale(1.1) rotateZ(180deg);
		-ms-transform: scale(1.1) rotateZ(180deg);
		-webkit-transform: scale(1.1) rotateZ(180deg);
		-moz-transform: scale(1.1) rotateZ(180deg);
}
.cssload-thecube .cssload-c4 {
	transform: scale(1.1) rotateZ(270deg);
		-o-transform: scale(1.1) rotateZ(270deg);
		-ms-transform: scale(1.1) rotateZ(270deg);
		-webkit-transform: scale(1.1) rotateZ(270deg);
		-moz-transform: scale(1.1) rotateZ(270deg);
}
.cssload-thecube .cssload-c2:before {
	animation-delay: 0.35s;
		-o-animation-delay: 0.35s;
		-ms-animation-delay: 0.35s;
		-webkit-animation-delay: 0.35s;
		-moz-animation-delay: 0.35s;
}
.cssload-thecube .cssload-c3:before {
	animation-delay: 0.69s;
		-o-animation-delay: 0.69s;
		-ms-animation-delay: 0.69s;
		-webkit-animation-delay: 0.69s;
		-moz-animation-delay: 0.69s;
}
.cssload-thecube .cssload-c4:before {
	animation-delay: 1.04s;
		-o-animation-delay: 1.04s;
		-ms-animation-delay: 1.04s;
		-webkit-animation-delay: 1.04s;
		-moz-animation-delay: 1.04s;
}



@keyframes cssload-fold-thecube {
	0%, 10% {
		transform: perspective(385px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		transform: perspective(385px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		transform: perspective(385px) rotateY(180deg);
		opacity: 0;
	}
}

@-o-keyframes cssload-fold-thecube {
	0%, 10% {
		-o-transform: perspective(385px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-o-transform: perspective(385px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-o-transform: perspective(385px) rotateY(180deg);
		opacity: 0;
	}
}

@-ms-keyframes cssload-fold-thecube {
	0%, 10% {
		-ms-transform: perspective(385px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-ms-transform: perspective(385px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-ms-transform: perspective(385px) rotateY(180deg);
		opacity: 0;
	}
}

@-webkit-keyframes cssload-fold-thecube {
	0%, 10% {
		-webkit-transform: perspective(385px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-webkit-transform: perspective(385px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-webkit-transform: perspective(385px) rotateY(180deg);
		opacity: 0;
	}
}

@-moz-keyframes cssload-fold-thecube {
	0%, 10% {
		-moz-transform: perspective(385px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		-moz-transform: perspective(385px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		-moz-transform: perspective(385px) rotateY(180deg);
		opacity: 0;
	}
}


.currency-error-message {
    padding-left : 40px
}

.cc-red {
	color : red		
}

.cc-blue {
	color : blue	
}

.cc-green {
	color : green	
}

.cc-orange {
	color : orange	
}

ul.fleet-react-autosuggest__suggestions-list {
	list-style-type: none;
	padding-left: 0px !important;
	cursor:  pointer;
	max-height:400px;
	overflow: auto;

}

.fleet-react-autosuggest__suggestion--highlighted {
	background-color: #dddddd;
	
}

.fleet-sugguestion-item { 
	min-height: 30px;
	display: flex;
	padding-left:10px;
	justify-content: flex-start;
	align-items: center
}

table tr td p{
	word-break: normal
}
table tr.p-no-margin td p{
	word-break: keep-all;
	margin-bottom:0
}
.bold,
label.bold{
    font-weight:bold;
}

.width-80-percent {
	width : 80%;
}
.model-super-large{
	width: 70%;
	min-width: 800px;
	max-width: 1366px;
}
p.total-edited{
	color: red;
}

.form-control.date-time-picker:read-only {
  background-color:  #fff
}


.form-control.date-time-picker:disabled {
  background-color:  #eee
}
.auto-refresh-button{
	padding: 5px 10px;
}
input.form-control.pickup-time{
	background: #fff;
}
.model-image-remove{
	position: absolute;
	top: 5px;
	right: 5px;
}
.image-container{
	max-width: 568px;
	width: 568px;
	height: 318px;
	overflow: hidden;
	position: relative;
	text-align: center;
}
.model-poster{
	width: 100%;
}
.model-image-remove {
	position: absolute;
	top: 0;
	right: 5px;
	color: #000;
	text-shadow: #ccc 1px 2px;
	font-size: 2.5em;
	font-weight: bold;
	cursor: pointer;
}
.model-image-remove:hover{
	color: red;
}
img.model-thumbnail {
	width: 100%;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 20px auto;
}

.btn.upload {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.video-dialog{
	width: 90%;
}
.video-container{
	width: 568px;
	max-width: 568px;
	height: 318px;
	max-height: 318px;
	text-align: center;
}
.user-avatar{
	max-width: 100%;
	max-height: 100%;
}
.content-with-submenu {
	padding: 10px 0 0 170px;
}