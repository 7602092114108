/* Sidebar Styles */

.sidebar-wrapper {
    z-index: 900;
    position: fixed;
    width: 0;
    height: 100%;
    width: 170px;
    overflow-y: auto;
    background: #333333;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.sidebar-wrapper ul.sidebar-nav>li.menu-item:last-child,
.sidebar-wrapper ul.sidebar-nav>li.menu-item.sub:last-child{
    margin-bottom: 37px;
}
.sidebar-wrapper::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.sidebar-wrapper::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.sidebar-wrapper::-webkit-scrollbar-thumb
{
	background-color: #333333;
	border: 1px solid #555555;
}


.sidebar-wrapper.sub-menu{
    background: #4d4d4d;
}
.sidebar-wrapper.sub-menu .sidebar-nav li a{
    min-height:48px
}
.sidebar-nav {
    position: absolute;
    top: 0;
    width: 170px;
    margin: 0;
    padding: 0;
    list-style: none;
}
.collapsed .sidebar-nav {
    width: 50px;
}
.collapsed .sub-menu .sidebar-nav{
    width: 170px;
}
.sidebar-wrapper.collapsed{
    width: 50px;
}
.sidebar-wrapper span.menu-icon{
    font-size: 22px;
}
.sidebar-wrapper.collapsed span.menu-text{
    display: none;
}
.sidebar-nav li.sidebar-brand {
    width: inherit;
    max-width:inherit;
    text-indent: 0px;
    text-align: center;
}
.sidebar-nav li {
    text-indent: 5px;
    line-height: 40px;
    /* margin-bottom: 3px; */
}

.sidebar-nav li.menu-item{
    min-height: 55px
}
.sub-menu .sidebar-nav li.menu-item.sub{
    padding: 3px;
    border-bottom: 1px solid #333333;
    margin: 0;
}
.sub-menu .sidebar-nav li.menu-item.sub a{
    min-height: 28px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px;
}

.sub-menu .sidebar-nav li.menu-item.sub {
    min-height : 27px;
    line-height: 27px;
}

.sub-menu .sidebar-nav li.menu-item.sub .sub-level2 li.menu-item{
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #261b14
}

.sub-menu .sidebar-nav li.menu-item.sub .sub-level2 li.menu-item.active,
.sub-menu .sidebar-nav li.menu-item.sub .sub-level2 li.menu-item:hover{
    background: #403d3d;
    font-size: 1.1em;
    font-weight: bold;
}

.sub-menu .sidebar-nav li.menu-item.sub .sub-level2 a{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row
}

.sub-menu .sidebar-nav li.menu-item.sub .sub-level2{
    padding: 0;
    list-style: none;
    background: #403d3d;
    margin: -3px;
}

.sub-menu .sidebar-nav li.menu-item.sub .sub-level2 li.menu-item.active a,
.sub-menu .sidebar-nav li.menu-item.sub .sub-level2 li.menu-item a:hover{
    color: #f2b538;
}

.sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #fff;
    padding-left: 0px;
    min-height: 40px;
    padding: 7px;
}

.sub-menu .sidebar-nav li a {
    color: #fff;
    padding-left: 5px;
    min-height: 40px;
}

.sub-menu .sub .sidebar-nav li a{
    min-height: 10px;
}
.sidebar-nav li.sidebar-brand a {
    padding-left: 0px;
    min-height:40px;
    padding-top:4px
}


.sidebar-nav li:hover,
.sidebar-nav li.active {
    text-decoration: none;
    color: #f2b538;
    background: #4d4d4d;
}

.sub-menu .sidebar-nav li:hover,
.sub-menu .sidebar-nav li.active {
    text-decoration: none;
    color: #f2b538;
    background: #7c7c7c;
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
    text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
    height: 54px;
    font-size: 18px;
    line-height: 46px;
    background: #000;
}

.sidebar-nav > .sidebar-brand a {
    color: #fff;
}

.sidebar-nav > .sidebar-brand a:hover {
    color: #f2b538;
    background: none;
}
.menu-addnew{
    
    cursor: pointer;
}

.menu-addnew .booking-new-icon{
    background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAw1BMVEUAAAD+tiT/vx/+qRz/shn+uRf/qhX+sBP+thL3sRf3rBb3rhX3sRz4sxv4rhr5sRv5rRv5rxr5sBr8sRj8rhf8rxr8sBr8rhn6rxj6sBn7sBj7rxj7sBj7rxn7sBn7rxn7sBn7sBf7rxf7sBj7sBj7rhj7rxn7rhn7rxn7rhf7sBf7rhj7sBn7rhn7sBf7sBn6rhf5rxn5sBn5sBn5rhf5sBf5sBn5sBn5rhn5rhf5sBf5rxf5rhf5sBf5sBn5rhn7sBkwf+b7AAAAQHRSTlMABwgJCgsMDQ4hIiMkJSYuLzAxVVZXWFl0eIWGiYqMl5iZmpucnsfIys7P0dLU2d3h5+nq6+zw8/T19vf4+fr7FSL8vgAAARZJREFUOMuFk21fgjAUxY+aGGGPlkKpYShRauYUcxpz3/9TpYxgwhjn1eWeP7/dhw1I1ep5QcjYOnjrGijqZrLjqajXztmN4YGfifXrsn895wVNrcx/WHGFyH36v9I/EkkhF3NeolkjBoZSitr2XvocxP3J9YfARu7ldMiElwN8DFzudAA10OM6gD/B0wMjBHrgHWFyWBjrC1iIiIr8CkwEdn67jshHVcAv1iLYb2ItgG8RJQMlVUX6VW2+oqsHOmhRHfDTBMY6wD3202blQGSeOh7I23Mc+cI8iys/K7tyHzUxNIuo/aX5P9Y7JbG8zQZvTYv+pymvpt5n53b0Usttz/K2mb0dXSnet/Ho+iSKiO92mln2Dz2+tKf0M8SJAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-size:auto 25px;
}

.sidebar-wrapper.collapsed .menu-text {
    display: none!important
}

.sidebar-wrapper .menu-text span {
    font-size: 13px;
    font-weight: 400
}

.sub-arrow{
    position: absolute;
    right: 5px;
}