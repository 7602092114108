.navigator{
	margin-bottom:0;
	border-radius: 0;
	z-index: 1000;
	min-height: 50px;
}
.navigator .container{
	width: 100%;
	margin: 0;
}
ul.dropdown-menu{
	padding: 5px;
}
.fleet-container{
	max-height: 400px;
	overflow-x: hidden;
}
.fleet-item img{
	margin-right: 3px; 
}
.fleet-item:hover{
	background: #cccccc;
}

ul.dropdown-menu.active{
	display: block;
}
.fleet-container li.fleet-item{
	padding: 3px;
}
.selected-fleet img{
	margin-right: 3px; 
}
.page-title{
    font-size: 20px;
    padding: 10px;
    width: 90%;
    position: absolute;
    text-align: center;
    display: inline-block;
    margin: 0 auto;
}
.fleet-list{
	z-index: 9999;
}
.noti ul.dropdown-menu{
	padding: 10px 0 0 0;
}
.noti-header{
	width: 400px;
	text-align: center;
	color: blue;
	font-weight: bold;
	padding-bottom: 10px;
}
.noti-list{
	max-height: 400px;
	overflow-x: hidden;
	overflow-y: scroll;
	margin: 0;
}
.noti-item{
	padding: 5px 10px;
}
.noti-item:hover,
.noti-item.unread:hover{
	background: #e4e9f2;
	cursor: pointer;
	box-shadow: 2px 2px;
}
.noti-item-title{
	margin-bottom: 2px;
}
.noti-item-time{
	color: #ccc
}
.noti-item{
	border-top: 1px solid #ddd;
}
.noti-item:first-child {
    border-radius: 0;
}
.noti-item.unread{
	background: #f0f3f8;
}
.fa.noti-unread{
	color: red;
	font-weight: bold;
}
.noti-item.no-messaeg{
	font-weight: bold;
	text-align: center;
}
.noti-item .noti-delete{
	position: absolute;
    top: 5px;
    right: 5px;
    color: #a0585d;
    font-size: 1.3em;
	opacity: 0.3;
}
.noti-item .noti-delete:hover{
	color: red;
	text-shadow: 1px 1px;
	opacity: 1;
}