body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
i.fa {
  font-size: 1.2em;
  margin-right: 4px;
}
.confirm-dialog {
  margin-top: 10%;
}
.modal-backdrop {
  z-index: 1050;
}

.bottom-button-group {
}

.mt-5 {
  margin-top: 5px;
}
.sub-controls {
  padding: 5px 0 5px 30px;
}
span.require {
  color: red;
}
.input-group-addon.action:hover {
  background: #ccc;
  color: red;
}
button.btn span.glyphicon,
button.btn i.fa {
  margin-right: 5px;
}
.modal-footer .control-label {
  margin-right: 10px;
}
.location-select {
}
td.item-no-found {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
  height: 50px;
  width: 100%;
  border-radius: 0 !important;
}
.upper {
  text-transform: uppercase;
}
.lower {
  text-transform: lowercase;
}
a i.fa {
  cursor: pointer;
}
i.fa.fa-2x {
  font-size: 2em;
}
i.fa.fa-3x {
  font-size: 3em;
}
.intl-tel-input {
  width: 100%;
}

/*margin option*/
.mr-l-5 {
  margin-left: 5px;
}
.mr-l-10 {
  margin-left: 10px;
}
.mr-l-20 {
  margin-left: 20px;
}
.mr-r-5 {
  margin-right: 5px;
}
.mr-r-10 {
  margin-right: 10px;
}
.mr-r-20 {
  margin-right: 20px;
}
.mr-r-50 {
  margin-right: 50px;
}
.mr-l-50 {
  margin-left: 50px;
}
.mr-r-50 {
  margin-right: 50px;
}
.mr-t-5 {
  margin-top: 5px;
}
.mr-t-10 {
  margin-top: 10px;
}
.mr-t-20 {
  margin-top: 20px;
}
.mr-b-10 {
  margin-bottom: 10px;
}
.mr-b-5 {
  margin-bottom: 5px;
}
.mr-b-50 {
  margin-bottom: 50px;
}
/*padding option*/
.pd-5 {
  padding: 5px;
}
.pd-r-5 {
  padding-right: 5px;
}
.pd-l-5 {
  padding-left: 5px;
}
.pd-10 {
  padding: 10px;
}

pd-15 {
  padding: 15px;
}
label {
  font-weight: normal;
}
th {
  font-weight: normal;
}
.red {
  color: red;
}

i {
  cursor: pointer;
}
.block {
  display: block;
}
.form-inline .form-control {
  max-width: 100%;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clickable {
  cursor: pointer;
}
.upper-case {
  text-transform: uppercase;
}
.lower-case {
  text-transform: lowercase;
}
.full-width {
  width: 100%;
}
.Select-multi-value-wrapper .Select-input {
  height: 32px;
}
.Select-control {
  height: 34px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.corp-traveler-address {
  min-height: 80px;
}

.corp-traveler-checkbox {
  clear: both;
}
