.table{
    border-radius: 5px;
    margin: 0;
}
.table thead{
    background: #333333;
    color: #fff;
}
.cue-table-control{
    background: #e6e6e6;
    padding: 5px 10px;
}
.cue-table-control .pagination{
    margin: 0;
}
.cue-table-control.header{
    border-radius: 10px 10px 0 0;
}
.cue-table-control.footer{
    border-radius: 0 0 10px 10px;
}
.left-controls{

}
.control-group{
    margin: 0 5px;
}
.left-controls .control-group{
    float: left;
}
.right-controls .control-group{
    float: right;

}
.paging-lage{
    padding: 8px 5px;
}
.table-header-controls-container{
    background: #333333;
    border-bottom: 1px solid #ccc;
    padding: 10px;
}
.table-header-control{
    float: left;
    width: 150px;
    margin-right: 5px;
    min-height: 60px;
}
.table-header-control label{
    color: #fff;
}