.login-body{
	position: fixed;
	width: 100%;
	height: 100%;
	margin-top: -63px; 
	background: #1f67c7;
}
.bg-image{
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	width: 100%;
	padding: 10%;
	color: #fff;
	font-size: 14px;
}
.login-form{
	margin: 0 auto;
	background: #575757;
    background: rgba(24,22,21,.62);
	width: 500px;
	padding: 20px 65px 65px 65px;
	border-radius: 20px;
}
.pass-recovery,
.pass-recovery label{
	padding: 0;
	cursor: pointer;
}
.pass-recovery:hover,
.pass-recovery label:hover{
	color: red;
}
.pass-recovery a{
 color: #fff;
}
.login-error{
	display: block;
	text-align: center;
	width: 100%;
	color: red;
	margin: 10px 0;
}
.login-success{
	display: block;
	text-align: center;
	width: 100%;
	color: #fff;
	margin: 10px 0;
}
.login-form input{
	margin-top: 10px;
}
table.language-login{
	width: 80%;
	margin-top: 10px;
}

.text-expired-token {
	color: red;
	padding-bottom: 10px;
}
.logo-login{
	text-align: center;
}

.logo-login .logo-img{
	max-width: 100px;
	max-height: 100px;
}