.loading {
  pointer-events: none;
  transition: 400ms linear all;
}

.bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10002;
  display: none;
  width: 100%;
  height: 4px;
  background: #29d;
  border-radius: 0 1px 1px 0;
  transition: width 350ms;
}

.peg {
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 2px;
  border-radius: 50%;
  opacity: .45;
  box-shadow: #29d 1px 0 6px 1px;
}

.spinner {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 10002;
  pointer-events: none;
  transition: 350ms linear all;
}

.icon {
  width: 20px;
  height: 20px;
  border: solid #29d;
  border-width: 0 2px 2px 0;
  border-radius: 50%;
  animation: loading-bar-spinner 400ms linear infinite;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}